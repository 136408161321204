/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CheckmarkCircleM, DiscountM, PhoneM } from '@alphakits/icons';
import { Flex, Typography } from '@alphakits/ui';
import cn from 'classnames';

import styles from './index.module.css';

type Props = {
    title: string;
    color: string;
    discount: number;
    onlyPhone: boolean;
    selected: boolean;
    onClick: () => void;
};

export const ScheduleSlot: React.FC<Props> = ({
    title,
    color,
    selected,
    onClick,
    onlyPhone,
    discount,
}) => (
    <button
        onClick={ () => onClick() }
        className={ cn(styles.slot_schedule) }
        style={ { backgroundColor: color } }
        type="button"
    >
        <Flex gap="xxxs" className={ styles.main } justify="start" align="center">
            <Typography.Text weight="medium" view="caps" color="primary-inverted">
                { title }
            </Typography.Text>

            { !!discount && <DiscountM /> }
            { onlyPhone && <PhoneM /> }
        </Flex>

        { selected && (
            <span className={ styles.selected }>
                <CheckmarkCircleM />
            </span>
        ) }
    </button>
);
