import React from 'react';
import {
    Checkbox, FlexColumns, Select, Textarea, ToastPlate,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CancelOrderDto } from '@escapenavigator/types/dist/order/cancel-order.dto';
import { OrderCancelReasonEnum } from '@escapenavigator/types/dist/order/enum/order-cancel-reson.enum';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import {
    FineDescriptions,
    getOrderCancelationState,
    serializeRecord,
    validateByDto,
} from '@escapenavigator/utils/dist';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';

type Props = {
    t: TFunction;
    close: () => void;
    setOrder: (order: OrderRO) => void;
    order: OrderRO;
};

export const Cancel: React.FC<Props> = ({
    close, t, order, setOrder,
}) => {
    const { orders } = useApi();
    const {
        profile: { currency },
    } = useCurrentUser();

    const { cancelFetch, cancelError, cancelLoading } = useApiMethod({
        api: orders.cancel,
        successCallback: ({ data }) => {
            setOrder(data);
            close();
        },
    });

    const hasCertificates = !!order.certificates.length;

    return (
        <RestForm
            initialValues={ serializeRecord(CancelOrderDto, {
                cancelationType: OrderCancelReasonEnum.CLIENT,
            }) }
            validate={ validateByDto(t) }
            loading={ false }
            updating={ cancelLoading }
            removing={ false }
            title={ t('Отмена игры') }
            t={ t }
            close={ close }
            save={ async (data) => {
                cancelFetch({ data, id: order.id });
            } }
        >
            { ({
                values, errors, touched, setFieldValue, handleChange,
            }) => {
                const {
                    fineAmount, type, returnAmount, chargeAmount,
                } = getOrderCancelationState({
                    minHoursForFreeCanceling: order.slot?.rule.minHoursForFreeCanceling,
                    date: order.utcDate,
                    cancelationReason: values.cancelationType,
                    cancelationAmount: order.slot?.rule.cancelationAmount,
                    cancelationRule: order.slot?.rule.cancelationRule,
                    total: order.total,
                    slotDiscount: order.slotDiscount,
                    returnCertificates: values.returnCertificates,
                    certificates: order.certificates,
                    payed: order.payed,
                    minimalPrice: +Object.values(order.price)[0],
                    transactions: order.transactions,
                });

                return (
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        <Select
                            dataTestId="cancelationType"
                            selected={ values.cancelationType }
                            label={ t('Причина отмены') }
                            onChange={ ({ selected }) =>
                                setFieldValue('cancelationType', selected?.key) }
                            options={ [
                                {
                                    key: OrderCancelReasonEnum.CLIENT,
                                    content: t(`cancelationReason.${OrderCancelReasonEnum.CLIENT}`),
                                },
                                {
                                    key: OrderCancelReasonEnum.QUESTROOM,
                                    content: t(
                                        `cancelationReason.${OrderCancelReasonEnum.QUESTROOM}`,
                                    ),
                                },
                            ] }
                            block={ true }
                            optionsListWidth="field"
                        />

                        { values.cancelationType !== OrderCancelReasonEnum.QUESTROOM &&
                            hasCertificates && (
                            <Checkbox
                                dataTestId="returnCertificates"
                                label={ t('returnCertificates') }
                                checked={ values.returnCertificates }
                                onChange={ (e, { checked }) =>
                                    setFieldValue('returnCertificates', checked) }
                            />
                        ) }

                        { values.cancelationType === OrderCancelReasonEnum.QUESTROOM && (
                            <Textarea
                                value={ values.cancelationReason }
                                onChange={ handleChange('cancelationReason') }
                                error={ touched.cancelationReason && errors.cancelationReason }
                                required={ true }
                                block={ true }
                                maxLength={ 500 }
                                counter={ true }
                                label={ t('Причина') }
                                hint={ t('cancelReasonTitleHint') }
                            />
                        ) }

                        <ToastPlate
                            title={
                                fineAmount
                                    ? t('cancelationReason.titleFine', {
                                        value: formatAmount(fineAmount, currency),
                                    })
                                    : t('cancelationReason.titleNoFine')
                            }
                            view={ fineAmount ? 'default' : 'positive' }
                        >
                            { t(`cancelationReason.${type}`, {
                                value: formatAmount(
                                    type === FineDescriptions.WITH_CHARGE
                                        ? chargeAmount
                                        : returnAmount,
                                    currency,
                                ),
                            }) }
                        </ToastPlate>

                        { cancelError && (
                            <ToastPlate view="negative" title="Error">
                                { cancelError.message }
                            </ToastPlate>
                        ) }
                    </FlexColumns>
                );
            } }
        </RestForm>
    );
};
