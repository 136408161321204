import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ChevronDownM,
    ChevronDownS,
    ChevronForwardM,
    CloseS,
    MessageS,
    PencilM,
    PlusS,
} from '@alphakits/icons';
import {
    Amount,
    Button,
    Collapse,
    Divider,
    Dot,
    FlexColumns,
    formatDate,
    ListAllert,
    ModalContext,
    Textarea,
    Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import { CreateOrderDto } from '@escapenavigator/types/dist/order/create-order.dto';
import { SlotRO } from '@escapenavigator/types/dist/slot/slot.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { serializeSlot } from '@escapenavigator/utils/dist/serialize-slot';
import { ClientSearch } from 'src/components/client-search';
import { Picker } from 'src/components/picker';
import { SummaryCell } from 'src/components/summary-cell';
import { TextCell } from 'src/components/text-cell';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectQuestroomById, updateSlot } from 'src/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

import { ClientModal } from '../client';
import { AddUpselling } from '../order/sub-modals';
import { BookingRuleDescription } from '../slots/booking-rules-description';

import { ChangeSlot } from './sub-modals/change-slot';

type Props = {
    close: () => void;
    initialSlot: SlotRO;
    successCallback?: () => void;
};

export const OrderNewModal: React.FC<Props> = ({ close, initialSlot, successCallback }) => {
    const { orders } = useApi();
    const { i18n, t } = useTranslation();
    const dispatch = useAppDispatch();
    const { openModal } = useContext(ModalContext);

    const [slot, setSlot] = useState(initialSlot);
    const [showComment, setShowComment] = useState(false);

    const questroom = useAppSelector(selectQuestroomById(slot.questroomId));

    const {
        profile: { currency, language, availableLanguages },
    } = useCurrentUser();

    const [client, setClient] = useState<ClientRO>();

    const { createError, createFetch, createLoading } = useApiMethod({
        api: orders.create,
        successCallback: ({ data }) => {
            dispatch(
                updateSlot({
                    id: slot.id,
                    changes: serializeSlot({
                        slot: { ...slot, tariff: { price: slot.tariff } },
                        order: data,
                    }),
                }),
            );
            if (successCallback) successCallback();

            close();
        },
    });

    const handleOpenClientModal = () => {
        openModal(ClientModal, 'm', true)({ recordId: client.id, cb: setClient });
    };

    const handleOpenSlotModal = () => {
        openModal(ChangeSlot)({ slot, setSlot });
    };

    const playersOptions = useMemo(
        () =>
            Object.entries(slot.tariff)
                .filter(([, price]) => !!price)
                .map(([players, price]) => ({
                    key: +players,
                    content: `${players} ${t('ppl')} - ${formatAmount(price, currency)}`,
                })),
        [slot, currency, t],
    );

    return (
        <RestForm
            title={ t('Оформление новой игры') }
            initialValues={ serializeRecord(CreateOrderDto, {
                language,
                slotId: slot.id,
                upsellings: [],
            }) }
            validate={ validateByDto(t) }
            save={ (values) => createFetch({ data: values }) }
            updating={ createLoading }
            softError={ createError?.message }
            close={ close }
            t={ t }
            submitButtonText={ t('Оформить') }
        >
            { ({
                values, setFieldValue, errors, touched, handleChange,
            }) => {
                const PickerButton = () => (
                    <Picker
                        block={ true }
                        view="ghost"
                        content={ (
                            <SummaryCell
                                leftAddons={ <ChevronDownS /> }
                                title={ `${t('gameLink')}, ${values.players} ${t('ppl')}` }
                                rightAddons={ (
                                    <Amount
                                        value={ +slot.tariff[values.players] }
                                        currency={ currency }
                                        color="primary"
                                        type="decimal"
                                        view="title"
                                    />
                                ) }
                            />
                        ) }
                        t={ t }
                        actions={ Object.fromEntries(
                            playersOptions.map(({ key, content }) => [
                                content,
                                () => setFieldValue('players', key),
                            ]),
                        ) }
                    />
                );

                const priceForPlayers = +slot.tariff[values.players];
                const slotDiscount = Math.round(priceForPlayers * (slot.discount / 10000));

                const total =
                    priceForPlayers -
                    slotDiscount +
                    values.upsellings.reduce(
                        (acc, u) => acc + (u.byEachPerson ? u.amount * values.players : u.amount),
                        0,
                    );

                return (
                    <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            { slot.breakReason && (
                                <ListAllert view="attention" text={ slot.breakReason } />
                            ) }

                            <TextCell
                                view="header"
                                title={ questroom.title }
                                onClick={ handleOpenSlotModal }
                                subtitle={ formatDate(slot.date, {
                                    lang: i18n.language,
                                    time: slot.start,
                                }) }
                                rightAddons={ <PencilM /> }
                            />

                            { availableLanguages.length > 1 && (
                                <Picker
                                    t={ t }
                                    block={ true }
                                    actions={ Object.fromEntries(
                                        availableLanguages.map((l) => [
                                            l.toUpperCase(),
                                            () => setFieldValue('language', l),
                                        ]),
                                    ) }
                                    content={ (
                                        <TextCell
                                            title={ t('order.gameLanguage') }
                                            subtitle={ values.language.toUpperCase() }
                                            rightAddons={ <ChevronDownM /> }
                                        />
                                    ) }
                                />
                            ) }

                            <Collapse
                                collapsedLabel={ t('showBookingRules') }
                                expandedLabel={ t('Скрыть') }
                                dataTestId="minHoursForBookingCollapse"
                            >
                                <FlexColumns columns={ 1 } gr={ 8 }>
                                    <BookingRuleDescription rule={ slot.rule } />
                                </FlexColumns>
                            </Collapse>
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <TextCell
                                view="header"
                                onClick={ client ? handleOpenClientModal : undefined }
                                title={ client ? getFullName(client) : t('tables.client') }
                                subtitle={ client?.phone }
                                rightAddons={ client && <ChevronForwardM /> }
                            />

                            { client && (
                                <React.Fragment>
                                    <TextCell title="Email" subtitle={ client.email } />

                                    <div>
                                        <Button
                                            size="xs"
                                            onClick={ () => {
                                                setFieldValue('clientId', undefined);
                                                setClient(undefined);
                                            } }
                                            view="outlined"
                                        >
                                            { t('Отменить выбор клиента') }
                                        </Button>
                                    </div>
                                </React.Fragment>
                            ) }

                            { !client && (
                                <ClientSearch
                                    t={ t }
                                    error={ touched.clientId && errors.clientId }
                                    onSelect={ (v) => {
                                        setFieldValue('clientId', v.id);
                                        setClient(v);
                                    } }
                                />
                            ) }
                        </FlexColumns>

                        { client && !values.players && (
                            <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                                { playersOptions.map((p) => (
                                    <Button
                                        size="s"
                                        onClick={ () => setFieldValue('players', p.key) }
                                        view="outlined"
                                    >
                                        { p.content }
                                    </Button>
                                )) }
                            </FlexColumns>
                        ) }

                        { client && values.players && (
                            <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                                <TextCell
                                    view="header"
                                    title={ t('Итого к оплате') }
                                    rightAddons={ (
                                        <Typography.Title view="xsmall" weight="bold" tag="div">
                                            <Amount
                                                currency={ currency }
                                                type="decimal"
                                                weight="bold"
                                                value={ total }
                                            />
                                        </Typography.Title>
                                    ) }
                                />

                                <Divider />

                                <FlexColumns columns={ 1 } gr={ 12 } gc={ 0 }>
                                    <PickerButton />

                                    { slot.discount && (
                                        <SummaryCell
                                            leftAddons={ <Dot size="sm" color="grey" /> }
                                            rightAddons={ (
                                                <Amount
                                                    currency={ currency }
                                                    type="decimal"
                                                    view="title"
                                                    value={ slotDiscount }
                                                />
                                            ) }
                                            title={ `${t('Скидка на выбранное время')} (${Math.round(
                                                slot.discount / 100,
                                            )}%)` }
                                        />
                                    ) }

                                    { values.upsellings?.map((upselling) => (
                                        <SummaryCell
                                            leftAddons={ <CloseS /> }
                                            onClick={ () =>
                                                setFieldValue(
                                                    'upsellings',
                                                    values.upsellings.filter(
                                                        (u) => u.id !== upselling.id,
                                                    ),
                                                ) }
                                            title={ `${upselling.title}` }
                                            rightAddons={ (
                                                <Amount
                                                    value={
                                                        upselling.byEachPerson &&
                                                        !upselling.flexiblePrice
                                                            ? upselling.amount * values.players
                                                            : upselling.amount
                                                    }
                                                    view="title"
                                                    currency={ currency }
                                                    type="decimal"
                                                />
                                            ) }
                                        />
                                    )) }

                                    <SummaryCell
                                        leftAddons={ <PlusS /> }
                                        onClick={ () => {
                                            openModal(AddUpselling)({
                                                questroomId: slot.questroomId,
                                                addUpsellings: ({ upsellings }) =>
                                                    setFieldValue('upsellings', upsellings),
                                            });
                                        } }
                                        title={ t('openUpsellingModal') }
                                    />

                                    { showComment ? (
                                        <Textarea
                                            label="Client comment"
                                            value={ values.comment }
                                            onChange={ handleChange('comment') }
                                        />
                                    ) : (
                                        <SummaryCell
                                            onClick={ () => setShowComment(true) }
                                            leftAddons={ <MessageS /> }
                                            title={ t('Add comment for moderators') }
                                        />
                                    ) }
                                </FlexColumns>
                            </FlexColumns>
                        ) }
                    </FlexColumns>
                );
            } }
        </RestForm>
    );
};
